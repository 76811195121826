const config = {
  port: import.meta.env.REACT_APP_PORT || '',
  baseDomain: import.meta.env.REACT_APP_BASE_DOMAIN || 'localtixio.com',
  domain: import.meta.env.REACT_APP_DOMAIN || 'app.localtixio.com',
  apiUrl: import.meta.env.REACT_APP_API_URL,
  socketUrl: import.meta.env.REACT_APP_SOCKET_URL || '',
  gmailUrl: import.meta.env.REACT_APP_GMAIL_URL || 'https://mail.google.com',
  outlookUrl: import.meta.env.REACT_APP_OUTLOOK_URL || 'https://signin.live.com',
  yahooUrl: import.meta.env.REACT_APP_YAHOO_URL || 'https://signin.yahoo.com',
  feedApiUrl: import.meta.env.REACT_APP_FEED_API_URL,
  S3: {
    endpoint: import.meta.env.REACT_APP_S3_ENDPOINT,
  },
  chromeExtensionId: import.meta.env.REACT_APP_CHROME_EXTENTION_ID,
  logrocketAppId: import.meta.env.REACT_APP_LOGROCKET_APP_ID || '',
  gaTrackingCode: import.meta.env.REACT_APP_GA_TRACKING_CODE || '',
  gAdsTrackingCode: import.meta.env.REACT_APP_GADS_TRACKING_CODE || '',
  fbPixelTrackingCode: import.meta.env.REACT_APP_FBPIXEL_TRACKING_CODE || '',
  quoraPixelTrackingCode: import.meta.env.REACT_APP_QUORAPIXEL_TRACKING_CODE || '',
  redditPixelTrackingCode: import.meta.env.REACT_APP_REDDITPIXEL_TRACKING_CODE || '',

  google: {
    clientId: import.meta.env.REACT_APP_GOOGLE_CLIENT_ID,
    callbackUrl: import.meta.env.REACT_APP_GOOGLE_DRIVE_CALLBACK_URL,
    authCallbackUrl: import.meta.env.REACT_APP_GOOGLE_AUTH_CALLBACK_URL,
  },
  facebook: {
    authCallbackUrl: import.meta.env.REACT_APP_FACEBOOK_AUTH_CALLBACK_URL,
  },
  slack: {
    authCallbackUrl: import.meta.env.REACT_APP_SLACK_AUTH_CALLBACK_URL,
  },
  dropbox: {
    clientId: import.meta.env.REACT_APP_DROPBOX_CLIENT_ID,
    callbackUrl: import.meta.env.REACT_APP_DROPBOX_CALLBACK_URL,
  },
  onedrive: {
    clientId: import.meta.env.REACT_APP_ONEDRIVE_CLIENT_ID,
    callbackUrl: import.meta.env.REACT_APP_ONEDRIVE_CALLBACK_URL,
  },
  sentry: {
    dsn: import.meta.env.REACT_APP_SENTRY_DSN || '',
    environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT || '',
    release: import.meta.env.REACT_APP_SENTRY_RELEASE || '',
  },
  collaborationURL: import.meta.env.REACT_APP_COLLABORATION_URL || '',
  helpcrunchAppId: import.meta.env.REACT_APP_HELPCRUNCH_APP_ID || '',

  reservedSubDomains: [
    'app',
    'test',
    'api',
    'admin',
    'www',
    'dev',
    'staging',
    'production',
    'prod',
    'dashboard',
    'super-admin',
    'superadmin',
    'landing',
    'promotion',
    'promo',
    'campaign',
    'collab-server',
    'tixio',
  ],
};

export enum DriveRoute {
  DROPBOX = 'dropbox',
  GOOGLE_DRIVE = 'google-drive',
  ONE_DRIVE = 'one-drive',
  ALL_FILES = '/files',
  MANAGE_DRIVE = 'manage-drive',
}

export enum ActivatedPlans {
  APP_SUMO = 'APP_SUMO',
  BASIC_MONTHLY = 'BASIC_MONTHLY',
  BASIC_YEARLY = 'BASIC_YEARLY',
  TEAM_MONTHLY = 'TEAM_MONTHLY',
  TEAM_YEARLY = 'TEAM_YEARLY',
}

export default config;

export const SystemFontFamily = `system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`;

export const isDemo = import.meta.env.MODE === 'demo';
